<template>
  <div>
    <vs-button
      :disabled="htmlTemplate !== ''"
      class="my-3 mr-3"
      @click="openPopup()"
      v-text="
        lang.surveyIntegrations.htmlBuilder.popup.button[languageSelected]
      "
      type="flat"
    ></vs-button>
    <vs-button
      @click="restoreDefault()"
      class="my-3 mr-3"
      v-text="lang.surveyIntegrations.htmlBuilder.reset[languageSelected]"
      type="flat"
    ></vs-button>
    <div class="import_export_row_html w-full">
      <div class="mt-0 mb-3 mr-3">
        <ImportFile
          :key="importHtmlKey"
          :text="lang.surveyIntegrations.htmlBuilder.import[languageSelected]"
          @onChange="setNewHTML"
        />
      </div>
      <vx-tooltip
        v-if="importHtmlTooltip"
        :text="importHtmlTooltip"
        position="top"
      >
        <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" />
      </vx-tooltip>
      <div class="ml-3">
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <vs-input readonly placeholder="" class="fill" v-model="href" />
      </div>
    </div>
    <vs-popup
      content-class="popup_html_email"
      class="popup_html_email"
      :title="lang.surveyIntegrations.htmlBuilder.popup.title[languageSelected]"
      :active.sync="popup"
    >
      <div class="editor">
        <div class="popup_html_email_import_export">
          <ImportFile
            :key="importSettingsKey"
            @onChange="setNewSettings"
            accept="text/json"
            :text="
              lang.surveyIntegrations.htmlBuilder.settings.import[
                languageSelected
              ]
            "
          />
          <vs-button
            @click="exportSettings()"
            class="my-3 ml-3"
            v-text="
              lang.surveyIntegrations.htmlBuilder.settings.export[
                languageSelected
              ]
            "
          ></vs-button>
        </div>
        <form-group
          :label="
            lang.surveyIntegrations.htmlBuilder.logo.text[languageSelected] +
              ':'
          "
        >
          <div class="widget_settings_konaUpload">
            <EmailBuilderMedia
              :savedFile="getFile(props.logo)"
              :key="keyImage"
              @onChange="updateFile('logo', ...arguments)"
              ref="konaUpload"
            >
              <template>
                <vs-button
                  v-if="props.logo || props.preview_logo"
                  class="float-right m-0"
                  color="danger"
                  type="flat"
                  @click="removeLogo()"
                >
                  {{
                    lang.surveyIntegrations.htmlBuilder.logo.remove[
                      languageSelected
                    ]
                  }}
                </vs-button>
              </template>
            </EmailBuilderMedia>
          </div>
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.imageSize[languageSelected] +
              ':'
          "
        >
          <vs-input-number
            class="popup_html_email_number_input"
            max="117"
            min="0"
            color="primary"
            v-model="props.imageSize"
          />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.header.text[languageSelected] +
              ':'
          "
        >
          <vs-input placeholder="" class="fill" v-model="props.header.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.header.color.text[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.header.color.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.header.color.background[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.header.color.background" />
        </form-group>

        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.body.title[languageSelected] +
              ':'
          "
        >
          <vs-input placeholder="" class="fill" v-model="props.body.title" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.body.text[languageSelected] +
              ':'
          "
        >
          <quillEditor placeholder="" class="fill" v-model="props.body.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.body.color.title[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.body.color.title" />
        </form-group>

        <!-- 
          No tiene utilidad puesto el texto del body se utiliza editor HTML, igualmente queda por las dudas
          <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.body.color.text[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.body.color.text" />
        </form-group> -->
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.button.text[languageSelected] +
              ':'
          "
        >
          <vs-input placeholder="" class="fill" v-model="props.button.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.button.color.text[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.button.color.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.button.color.background[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.button.color.background" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.footer.title[languageSelected] +
              ':'
          "
        >
          <vs-input placeholder="" class="fill" v-model="props.footer.title" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.footer.text[languageSelected] +
              ':'
          "
        >
          <vs-input placeholder="" class="fill" v-model="props.footer.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.footer.color.title[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.footer.color.title" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.footer.color.text[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.footer.color.text" />
        </form-group>
        <form-group
          :customCol="customCol"
          :label="
            lang.surveyIntegrations.htmlBuilder.footer.color.background[
              languageSelected
            ] + ':'
          "
        >
          <input type="color" v-model="props.footer.color.background" />
        </form-group>
      </div>
      <div class="widget_settings_footer footer">
        <vs-button color="primary" type="flat" @click="restoreDefault()">
          <span>
            {{
              lang.botMaker.addBot.widget.defaultConfig.restore[
                languageSelected
              ]
            }}
          </span>
        </vs-button>
        <vs-button color="danger" type="flat" @click="cancel()">{{
          lang.botMaker.cancel[languageSelected]
        }}</vs-button>
        <vs-button color="primary" type="filled" @click="save()">
          <span>
            {{ lang.botMaker.editBot.saveBot[languageSelected] }}
          </span>
        </vs-button>
      </div>
    </vs-popup>
    <div class="iframe_email_html_template">
      <iframe
        :key="keyIframeEmail"
        frameBorder="0"
        :srcdoc="htmlEmail"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { emailTemplate, defaultTemplate } from './template/emailTemplate.js'
import EmailBuilderMedia from './EmailBuilderMedia.vue'
import { quillEditor } from 'vue-quill-editor'
import BotMakerService from '@/services/botMaker.service'
import ImportFile from './ImportFile.vue'
import { isEqual } from 'lodash'
import juice from 'juice'
/** Imports from quill to use inline css */
import Quill from 'quill'
var DirectionAttribute = Quill.import('attributors/attribute/direction')
Quill.register(DirectionAttribute, true)
var AlignClass = Quill.import('attributors/class/align')
Quill.register(AlignClass, true)
var BackgroundClass = Quill.import('attributors/class/background')
Quill.register(BackgroundClass, true)
var ColorClass = Quill.import('attributors/class/color')
Quill.register(ColorClass, true)
var DirectionClass = Quill.import('attributors/class/direction')
Quill.register(DirectionClass, true)
var FontClass = Quill.import('attributors/class/font')
Quill.register(FontClass, true)
var SizeClass = Quill.import('attributors/class/size')
Quill.register(SizeClass, true)
var AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
var BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)
var ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)
var DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)
var FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)
var SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

import FormGroup from '@/views/home/bot-maker/add-bot/Components/FormGroup'

export default {
  props: {
    href: {
      type: String,
      required: true
    },
    bot: {
      type: Object,
      required: true
    }
  },
  components: {
    ImportFile,
    EmailBuilderMedia,
    FormGroup,
    quillEditor
  },
  data() {
    return {
      keyIframeEmail: new Date().getTime() * 1.3,
      importSettingsKey: new Date().getTime() * 1.1,
      current: null,
      keyImage: new Date().getTime(),
      importHtmlKey: new Date().getTime() * 1.2,
      customCol: 3,
      popup: false,
      htmlTemplate: '',
      startProps: null,
      props: {
        header: {
          text: 'Hola!',
          color: {
            text: '#FFFFFF',
            background: '#ff8100'
          }
        },
        body: {
          title: 'Encuesta',
          text:
            'Por favor, haz click {{aquí}} o en el botón de abajo para realizar la encuesta',
          color: {
            title: 'black',
            text: 'black'
          }
        },
        button: {
          color: {
            text: '#FFFFFF',
            background: '#ff8100'
          },
          text: 'Empezar encuesta'
        },
        footer: {
          title: 'Muchas gracias!',
          text: 'Tu opinión es muy importante para nosotros',
          color: {
            background: '#ff8100',
            title: '#ff8100',
            text: 'black'
          }
        },
        imageSize: 80,
        preview_logo:
          'https://konecta-dev.s3.amazonaws.com/image-5fd38759f99ab100135c4bd4',
        logo:
          'https://konecta-dev.s3.amazonaws.com/image-5fd38759f99ab100135c4bd4'
      }
    }
  },
  mounted() {
    this.languageUpdate(this.languageSelected, 'es')
  },
  watch: {
    emailTemplate(newVal) {
      this.updateHtml(newVal)
      this.keyIframeEmail = new Date().getTime()
    },
    htmlTemplate() {
      this.keyIframeEmail = new Date().getTime()
    },
    languageSelected(newVal, oldValue) {
      this.languageUpdate(newVal, oldValue)
    }
  },
  computed: {
    htmlEmail() {
      return this.htmlTemplate ? this.htmlTemplate : this.emailTemplate
    },
    ...mapState(['lang', 'languageSelected']),
    emailTemplate() {
      return emailTemplate(this.href, this.props)
    },
    importHtmlTooltip() {
      return this.lang.surveyIntegrations.htmlBuilder.errorHtmlTemplate[
        this.languageSelected
      ].replace(
        '%s',
        this.lang.surveyIntegrations.htmlBuilder.nextLinkHtmlTemplate[
          this.languageSelected
        ]
      )
    }
  },
  methods: {
    languageUpdate(newLang, oldLang) {
      const d = this.lang.surveyIntegrations.htmlBuilder.default
      if (this.props.header.text === d.header.text[oldLang]) {
        this.props.header.text = d.header.text[newLang]
      }
      if (this.props.body.title === d.body.title[oldLang]) {
        this.props.body.title = d.body.title[newLang]
      }
      if (this.props.body.text.includes(d.body.text[oldLang])) {
        this.props.body.text = d.body.text[newLang]
      }
      if (this.props.footer.title === d.footer.title[oldLang]) {
        this.props.footer.title = d.footer.title[newLang]
      }
      if (this.props.footer.text === d.footer.text[oldLang]) {
        this.props.footer.text = d.footer.text[newLang]
      }
      if (this.props.button.text === d.button.text[oldLang]) {
        this.props.button.text = d.button.text[newLang]
      }
    },
    setNewHTML(html) {
      let check = this.href
      let newHtml = juice(html)
      if (!newHtml.includes(check)) {
        if (newHtml.includes('</body>')) {
          /** Add invisible href of the survey, body tag required */
          newHtml = newHtml.replace(
            '</body>',
            `<a style="display:none" href="${check}"></a></body>`
          )
        } else {
          const text = this.lang.surveyIntegrations.htmlBuilder.errorHtmlTemplate[
            this.languageSelected
          ].replace('%s', check)
          this.$vs.notify({
            text: text,
            color: 'danger'
          })
          this.htmlTemplate = ''
          this.importHtmlKey = new Date().getTime()
          return
        }
      }
      this.htmlTemplate = newHtml
      this.popup = false
      this.$emit('update:html', this.htmlTemplate)
    },
    async exportSettings() {
      this.$vs.loading()
      await this.saveLogoFile()
        .catch(() => {
          this.$vs.notify({
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        })
      const text = JSON.stringify(this.props, null, 4)
      const fileName = 'email_settings.json'
      var element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
      )
      element.setAttribute('download', fileName)

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },
    // Se deja comentado por no saber si se dispondrá de su uso luego
    // exportHTML() {
    //   const text = this.emailTemplate
    //   console.log('HTML', text)
    //   const fileName = 'email_template.html'
    //   var element = document.createElement('a')
    //   element.setAttribute(
    //     'href',
    //     'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    //   )
    //   element.setAttribute('download', fileName)

    //   element.style.display = 'none'
    //   document.body.appendChild(element)

    //   element.click()

    //   document.body.removeChild(element)
    // },
    equalToDefault() {
      const nProps = defaultTemplate(this.lang, this.languageSelected)
      const nPropsJ = JSON.stringify(nProps)
      const cPropsJ = this.startProps + ''
      const cPropsJ2 = JSON.stringify(this.props)
      const areEqual =
        isEqual(JSON.parse(nPropsJ), JSON.parse(cPropsJ)) ||
        isEqual(cPropsJ, cPropsJ2)
      return areEqual
    },
    areYouSureSaveChanges(cb) {
      const areEqual = this.equalToDefault()
      if (areEqual) return cb(true)
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.treeView.tree.confirmation.title[
          this.languageSelected
        ],
        text: this.lang.surveyIntegrations.htmlBuilder.areYouSureSave[
          this.languageSelected
        ],
        acceptText: this.lang.treeView.tree.confirmation.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.treeView.tree.confirmation.cancelText[
          this.languageSelected
        ],
        accept: () => {
          cb(true)
        },
        cancel: () => {
          cb(false)
        }
      })
    },
    areYouSureRestoreDefault(cb) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.lang.treeView.tree.confirmation.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.addBot.widget.defaultConfig.textRestore[
          this.languageSelected
        ],
        acceptText: this.lang.treeView.tree.confirmation.acceptText[
          this.languageSelected
        ],
        cancelText: this.lang.treeView.tree.confirmation.cancelText[
          this.languageSelected
        ],
        accept: () => {
          cb(true)
        },
        cancel: () => {
          cb(false)
        }
      })
    },
    cancel() {
      this.popup = false
      this.props = { ...this.current }
      this.keyImage = new Date().getTime()
    },
    openPopup() {
      this.popup = true
      this.startProps = JSON.stringify(this.props)
      this.$nextTick(() => {
        document.querySelector('.vs-popup--content').scrollTop = 0
      })
      this.saveCurrent()
    },
    saveCurrent() {
      this.current = JSON.parse(JSON.stringify(this.props))
    },
    save() {
      this.areYouSureSaveChanges(async res => {
        if (res) {
          this.$vs.loading()
          await this.saveLogoFile().catch(() => {
            this.$vs.notify({
              text: this.lang.botMaker.error[this.languageSelected],
              color: 'danger'
            })
          })
          this.popup = false
          this.$nextTick(() => {
            this.$vs.loading.close()
          })
        }
      })
    },
    restoreDefault() {
      this.areYouSureRestoreDefault(restore => {
        if (restore) {
          this.importHtmlKey = new Date().getTime()
          this.htmlTemplate = ''
          this.props = defaultTemplate(this.lang, this.languageSelected)
          this.keyImage = new Date().getTime()
        }
      })
    },
    removeLogo() {
      this.props.logo = ''
      this.props.preview_logo = ''
      this.keyImage = new Date().getTime()
    },
    getBase64(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.props.preview_logo = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    },
    updateFile(widgetItem, file) {
      const fW = {
        logo: file => {
          this.props.logo = file
          this.getBase64(file)
        }
      }
      const fF = fW[widgetItem]
      fF(file)
    },
    async saveLogoFile() {
      const logo = this.props.logo
      if (logo && typeof logo !== 'string') {
        this.props.logo = await this.saveFile(logo, this.bot._id)
      }
    },
    async saveFile(file, botId) {
      const fileData = new FormData()
      fileData.append('file', file)
      return BotMakerService.fileUpload(fileData, botId).then(response => {
        return response.data.url
      })
    },
    setNewSettings(file) {
      try {
        this.props = JSON.parse(file)
        this.$vs.notify({
          text: this.lang.surveyIntegrations.htmlBuilder.importSettings.success[
            this.languageSelected
          ],
          color: 'success'
        })
      } catch (e) {
        this.$vs.notify({
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
      }
      this.importSettingsKey = new Date().getTime()
    },
    getFile(url) {
      if (!url || typeof url !== 'string') return null
      /** Get file for MediaPreview */
      return { name: '', fileData: '', fileUrl: url, fileFormat: 'jpg' }
    },
    updateHtml(newVal) {
      this.$emit('update:html', newVal)
    }
  }
}
</script>

<style lang="scss">
.popup_html_email_number_input {
  width: 100px;
}
.iframe_email_html_template {
  width: 100%;
  height: 800px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.import_export_row_html {
  display: flex;
  justify-content: left;
  align-items: center;
}
.popup_html_email {
  &_import_export {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .w-100 {
    width: 100%;
  }
  .widget_settings_konaUpload > div {
    width: 100%;
  }
  .widget_settings_footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    padding-bottom: 0;
    .vs-button {
      margin-right: 15px;
    }
  }
  .widget_settings_konaUpload {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .media-preview__preview {
      margin-bottom: 0;
    }
    .vs-con-input-label {
      width: 100%;
    }
  }
  .vs-popup {
    width: 1100px;
  }
}
</style>
