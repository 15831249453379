<template>
  <section class="rich-text-editor">
    <vx-card
      :title="
        lang.surveyIntegrations.channels.email.settings.email.title[
          languageSelected
        ]
      "
      class="overflow-hidden mb-2"
      :subtitle="
        lang.surveyIntegrations.channels.email.settings.email.subtitle[
          languageSelected
        ]
      "
    >
      <!-- Staging -->
      <div class="w-full staging">
        <label>
          {{
            lang.surveyIntegrations.channels.email.settings.version.title[
              languageSelected
            ]
          }}
        </label>
        <vx-slider v-model="staging" />
      </div>
      <!-- email from -->
      <vs-input
        type="email"
        :label="
          lang.surveyIntegrations.channels.email.settings.email.from[
            languageSelected
          ]
        "
        :placeholder="
          lang.surveyIntegrations.channels.email.settings.email.fromPlaceholder[
            languageSelected
          ]
        "
        class="w-full mt-2"
        v-model="from"
        @keyup="onFromChange"
        :class="{ 'is-invalid': errors.first('from') }"
        v-validate="'required|email'"
        name="from"
      />
      <span class="error-msg" v-if="errors.first('from')">
        {{ errors.first('from') }}
      </span>

      <!-- email subject -->
      <vs-input
        type="text"
        :label="
          lang.surveyIntegrations.channels.email.settings.email.subject[
            languageSelected
          ]
        "
        :placeholder="
          lang.surveyIntegrations.channels.email.settings.email.subject[
            languageSelected
          ]
        "
        class="w-full mt-2"
        v-model="subject"
        @keyup="onSubjectChange"
        :class="{ 'is-invalid': errors.first('subject') }"
        v-validate="'required'"
        name="subject"
      />
      <span class="error-msg" v-if="errors.first('subject')">
        {{ errors.first('subject') }}
      </span>
      <HtmlBuilder
        v-if="bot"
        :bot="bot"
        v-bind:html.sync="html"
        :href="href"
      ></HtmlBuilder>
      <span class="error-msg" v-if="errors.first('html')">
        {{ errors.first('html') }}
      </span>
    </vx-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import hljs from 'highlight.js'
import HtmlBuilder from './HtmlBuilder.vue'
import { Validator } from 'vee-validate'

// highlight.js style
import 'highlight.js/styles/tomorrow.css'

// import theme style
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import es from 'vee-validate/dist/locale/es'
import en from 'vee-validate/dist/locale/en'

export default {
  name: 'EmailBuilder',
  props: {
    value: Object,
    bot: Object
  },
  components: {
    HtmlBuilder
  },
  data() {
    return {
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            // [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },
      from: '',
      subject: '',
      html: '',
      href: '',
      staging: false
    }
  },
  watch: {
    languageSelected() {
      Validator.localize(
        this.languageSelected,
        this.languageSelected === 'es' ? es : en
      )
    },
    html() {
      this.emitValue()
    }
  },
  methods: {
    emitValue() {
      this.$emit('input', {
        from: this.from,
        subject: this.subject,
        html: this.html,
        staging: this.staging
      })
    },
    onFromChange() {
      this.emitValue()
    },
    onSubjectChange() {
      this.emitValue()
    },
    onEditorChange(value) {
      this.html = value.html
      this.emitValue()
    }
    // onEditorBlur(editor) {},
    // onEditorFocus(editor) {},
    // onEditorReady(editor) {}
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    editor() {
      return this.$refs.myTextEditor.quill
    },
    contentCode() {
      return hljs.highlightAuto(this.html).value
    }
  },
  mounted() {
    this.from = this.value.from
    this.subject = this.value.subject
    this.html = this.value.html
    this.href = this.value.href

    this.editorOption.placeholder = this.lang.surveyIntegrations.channels.email.settings.email.htmlPlaceholder[
      this.languageSelected
    ]

    Validator.localize(
      this.languageSelected,
      this.languageSelected === 'es' ? es : en
    )
  }
}
</script>

<style lang="scss">
.rich-text-editor {
  .vx-card {
    // display: flex;
    // flex-direction: column;
    overflow: hidden;
    min-height: 160px;

    .is-invalid {
      .vs-con-input input,
      .multiselect__tags {
        border: 1px solid rgba(var(--vs-danger), 1) !important;
      }
    }

    .error-msg {
      color: rgba(var(--vs-danger), 1) !important;
      font-size: 0.85rem;
      padding-left: 5px;
    }

    // .editor {
    //   height: 40rem;
    //   overflow: hidden;
    // }
    .staging {
      font-size: 0.85rem;
      padding-left: 5px;
      color: rgba(0, 0, 0, 0.7);
    }
    .editor,
    .output {
      // width: 50%;
      width: 100%;

      .ql-editor {
        min-height: 304px;

        .ql-snow .ql-tooltip {
          z-index: 2;
        }
      }

      .ql-toolbar.ql-snow {
        border-radius: 5px 5px 0 0;
      }
      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-radius: 0 0 5px 5px;
      }

      &.is-invalid {
        .ql-toolbar.ql-snow,
        .ql-toolbar.ql-snow + .ql-container.ql-snow {
          border-color: rgba(var(--vs-danger), 1) !important;
        }
      }
    }

    .output {
      // width: 100%;
      // height: 20rem;
      margin: 0;
      border: 1px solid #ccc;
      border-left: 0;
      overflow-y: auto;
      resize: vertical;

      &.code {
        padding: 1rem;
        height: 16rem;
      }

      &.ql-snow {
        // border-top: none;
        // height: 24rem;
      }

      .title {
        // height: $toolbar-height;
        // line-height: $toolbar-height;
        // padding-left: $gap;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
</style>
