var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vs-button',{staticClass:"my-3 mr-3",attrs:{"disabled":_vm.htmlTemplate !== '',"type":"flat"},domProps:{"textContent":_vm._s(
      _vm.lang.surveyIntegrations.htmlBuilder.popup.button[_vm.languageSelected]
    )},on:{"click":function($event){_vm.openPopup()}}}),_vm._v(" "),_c('vs-button',{staticClass:"my-3 mr-3",attrs:{"type":"flat"},domProps:{"textContent":_vm._s(_vm.lang.surveyIntegrations.htmlBuilder.reset[_vm.languageSelected])},on:{"click":function($event){_vm.restoreDefault()}}}),_vm._v(" "),_c('div',{staticClass:"import_export_row_html w-full"},[_c('div',{staticClass:"mt-0 mb-3 mr-3"},[_c('ImportFile',{key:_vm.importHtmlKey,attrs:{"text":_vm.lang.surveyIntegrations.htmlBuilder.import[_vm.languageSelected]},on:{"onChange":_vm.setNewHTML}})],1),_vm._v(" "),(_vm.importHtmlTooltip)?_c('vx-tooltip',{attrs:{"text":_vm.importHtmlTooltip,"position":"top"}},[_c('feather-icon',{attrs:{"icon":"InfoIcon","svgClasses":"h-5 w-5"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ml-3"},[_c('vs-input',{staticClass:"fill",attrs:{"readonly":"","placeholder":""},model:{value:(_vm.href),callback:function ($$v) {_vm.href=$$v},expression:"href"}})],1)],1),_vm._v(" "),_c('vs-popup',{staticClass:"popup_html_email",attrs:{"content-class":"popup_html_email","title":_vm.lang.surveyIntegrations.htmlBuilder.popup.title[_vm.languageSelected],"active":_vm.popup},on:{"update:active":function($event){_vm.popup=$event}}},[_c('div',{staticClass:"editor"},[_c('div',{staticClass:"popup_html_email_import_export"},[_c('ImportFile',{key:_vm.importSettingsKey,attrs:{"accept":"text/json","text":_vm.lang.surveyIntegrations.htmlBuilder.settings.import[
              _vm.languageSelected
            ]},on:{"onChange":_vm.setNewSettings}}),_vm._v(" "),_c('vs-button',{staticClass:"my-3 ml-3",domProps:{"textContent":_vm._s(
            _vm.lang.surveyIntegrations.htmlBuilder.settings.export[
              _vm.languageSelected
            ]
          )},on:{"click":function($event){_vm.exportSettings()}}})],1),_vm._v(" "),_c('form-group',{attrs:{"label":_vm.lang.surveyIntegrations.htmlBuilder.logo.text[_vm.languageSelected] +
            ':'}},[_c('div',{staticClass:"widget_settings_konaUpload"},[_c('EmailBuilderMedia',{key:_vm.keyImage,ref:"konaUpload",attrs:{"savedFile":_vm.getFile(_vm.props.logo)},on:{"onChange":function($event){
            var i = arguments.length, argsArray = Array(i);
            while ( i-- ) argsArray[i] = arguments[i];
_vm.updateFile.apply(void 0, [ 'logo' ].concat( argsArray ))}}},[[(_vm.props.logo || _vm.props.preview_logo)?_c('vs-button',{staticClass:"float-right m-0",attrs:{"color":"danger","type":"flat"},on:{"click":function($event){_vm.removeLogo()}}},[_vm._v("\n                "+_vm._s(_vm.lang.surveyIntegrations.htmlBuilder.logo.remove[
                    _vm.languageSelected
                  ])+"\n              ")]):_vm._e()]],2)],1)]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.imageSize[_vm.languageSelected] +
            ':'}},[_c('vs-input-number',{staticClass:"popup_html_email_number_input",attrs:{"max":"117","min":"0","color":"primary"},model:{value:(_vm.props.imageSize),callback:function ($$v) {_vm.$set(_vm.props, "imageSize", $$v)},expression:"props.imageSize"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.header.text[_vm.languageSelected] +
            ':'}},[_c('vs-input',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.header.text),callback:function ($$v) {_vm.$set(_vm.props.header, "text", $$v)},expression:"props.header.text"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.header.color.text[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.header.color.text),expression:"props.header.color.text"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.header.color.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.header.color, "text", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.header.color.background[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.header.color.background),expression:"props.header.color.background"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.header.color.background)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.header.color, "background", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.body.title[_vm.languageSelected] +
            ':'}},[_c('vs-input',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.body.title),callback:function ($$v) {_vm.$set(_vm.props.body, "title", $$v)},expression:"props.body.title"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.body.text[_vm.languageSelected] +
            ':'}},[_c('quillEditor',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.body.text),callback:function ($$v) {_vm.$set(_vm.props.body, "text", $$v)},expression:"props.body.text"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.body.color.title[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.body.color.title),expression:"props.body.color.title"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.body.color.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.body.color, "title", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.button.text[_vm.languageSelected] +
            ':'}},[_c('vs-input',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.button.text),callback:function ($$v) {_vm.$set(_vm.props.button, "text", $$v)},expression:"props.button.text"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.button.color.text[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.button.color.text),expression:"props.button.color.text"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.button.color.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.button.color, "text", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.button.color.background[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.button.color.background),expression:"props.button.color.background"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.button.color.background)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.button.color, "background", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.footer.title[_vm.languageSelected] +
            ':'}},[_c('vs-input',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.footer.title),callback:function ($$v) {_vm.$set(_vm.props.footer, "title", $$v)},expression:"props.footer.title"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.footer.text[_vm.languageSelected] +
            ':'}},[_c('vs-input',{staticClass:"fill",attrs:{"placeholder":""},model:{value:(_vm.props.footer.text),callback:function ($$v) {_vm.$set(_vm.props.footer, "text", $$v)},expression:"props.footer.text"}})],1),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.footer.color.title[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.footer.color.title),expression:"props.footer.color.title"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.footer.color.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.footer.color, "title", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.footer.color.text[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.footer.color.text),expression:"props.footer.color.text"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.footer.color.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.footer.color, "text", $event.target.value)}}})]),_vm._v(" "),_c('form-group',{attrs:{"customCol":_vm.customCol,"label":_vm.lang.surveyIntegrations.htmlBuilder.footer.color.background[
            _vm.languageSelected
          ] + ':'}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.props.footer.color.background),expression:"props.footer.color.background"}],attrs:{"type":"color"},domProps:{"value":(_vm.props.footer.color.background)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.props.footer.color, "background", $event.target.value)}}})])],1),_vm._v(" "),_c('div',{staticClass:"widget_settings_footer footer"},[_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){_vm.restoreDefault()}}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.addBot.widget.defaultConfig.restore[
              _vm.languageSelected
            ])+"\n        ")])]),_vm._v(" "),_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":function($event){_vm.cancel()}}},[_vm._v(_vm._s(_vm.lang.botMaker.cancel[_vm.languageSelected]))]),_vm._v(" "),_c('vs-button',{attrs:{"color":"primary","type":"filled"},on:{"click":function($event){_vm.save()}}},[_c('span',[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.editBot.saveBot[_vm.languageSelected])+"\n        ")])])],1)]),_vm._v(" "),_c('div',{staticClass:"iframe_email_html_template"},[_c('iframe',{key:_vm.keyIframeEmail,attrs:{"frameBorder":"0","srcdoc":_vm.htmlEmail}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }