<template>
  <div class="import_html_email">
    <vs-button
      class="my-3 mr-3 import_html_email_button"
      v-text="textButton"
      type="flat"
    >
    </vs-button>
    <KonaUpload
      :allowPreview="false"
      :placeholder="lang.botMaker.addBot.widget.onlyImage[languageSelected]"
      :key="keyImage"
      :accept="acceptedMediaTypes"
      @onChange="updateFile"
      ref="konaUpload"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    accept: {
      type: String,
      default: 'text/html'
    },
    savedFile: {
      type: Object, // { name: "", fileData: "", fileUrl: "", "fileFormat": "" }
      default: null
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fileName: '',
      keyImage: new Date().getTime(),
      showPreview: true
    }
  },
  components: {
    KonaUpload: () => import('@/components/KonaUpload.vue')
  },
  methods: {
    updateFile(newVal) {
      if (newVal && newVal.name) {
        this.fileName = newVal.name
      }
      this.showPreview = false
      var fr = new FileReader()
      fr.onload = () => {
        this.$emit('onChange', fr.result)
      }
      fr.readAsText(newVal)
    }
  },
  computed: {
    textButton() {
      if (this.fileName) {
        return this.fileName
      }
      return this.text
    },
    acceptedMediaTypes() {
      return this.accept
    },
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>

<style lang="scss">
.import_html_email {
  position: relative;
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  .import_html_email_button {
    margin: 0 !important;
    position: relative;
    z-index: 1;
  }
  .kona-upload {
    position: absolute;
    z-index: 2;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
