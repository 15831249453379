<template>
  <div>
    <section
      v-if="savedFile && showPreview"
      id="kona-upload"
      class="kona-upload"
    >
      <MediaPreview :savedFile="savedFile" />
    </section>
    <KonaUpload
      :placeholder="lang.botMaker.addBot.widget.onlyImage[languageSelected]"
      :key="keyImage"
      :accept="acceptedMediaTypes"
      @onChange="updateFile"
      ref="konaUpload"
    />
    <slot> </slot>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    savedFile: {
      type: Object, // { name: "", fileData: "", fileUrl: "", "fileFormat": "" }
      default: null
    }
  },
  data() {
    return {
      keyImage: new Date().getTime(),
      showPreview: true
    }
  },
  components: {
    KonaUpload: () => import('@/components/KonaUpload.vue'),
    MediaPreview: () => import('@/components/MediaPreview.vue')
  },
  methods: {
    updateFile(newVal) {
      if (newVal.type.includes('image/svg')) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.htmlBuilder.svgAlertTitle[
            this.languageSelected
          ],
          text: this.lang.surveyIntegrations.htmlBuilder.svgAlert[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      this.showPreview = false
      this.$emit('onChange', newVal)
    }
  },
  computed: {
    acceptedMediaTypes() {
      return 'image/*'
    },
    ...mapGetters('bots', ['bot', 'validations', 'sectionReady']),
    ...mapState(['lang', 'languageSelected'])
  }
}
</script>
