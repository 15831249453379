var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"rich-text-editor"},[_c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels.email.settings.email.title[
        _vm.languageSelected
      ],"subtitle":_vm.lang.surveyIntegrations.channels.email.settings.email.subtitle[
        _vm.languageSelected
      ]}},[_c('div',{staticClass:"w-full staging"},[_c('label',[_vm._v("\n        "+_vm._s(_vm.lang.surveyIntegrations.channels.email.settings.version.title[
            _vm.languageSelected
          ])+"\n      ")]),_vm._v(" "),_c('vx-slider',{model:{value:(_vm.staging),callback:function ($$v) {_vm.staging=$$v},expression:"staging"}})],1),_vm._v(" "),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"w-full mt-2",class:{ 'is-invalid': _vm.errors.first('from') },attrs:{"type":"email","label":_vm.lang.surveyIntegrations.channels.email.settings.email.from[
          _vm.languageSelected
        ],"placeholder":_vm.lang.surveyIntegrations.channels.email.settings.email.fromPlaceholder[
          _vm.languageSelected
        ],"name":"from"},on:{"keyup":_vm.onFromChange},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}}),_vm._v(" "),(_vm.errors.first('from'))?_c('span',{staticClass:"error-msg"},[_vm._v("\n      "+_vm._s(_vm.errors.first('from'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"w-full mt-2",class:{ 'is-invalid': _vm.errors.first('subject') },attrs:{"type":"text","label":_vm.lang.surveyIntegrations.channels.email.settings.email.subject[
          _vm.languageSelected
        ],"placeholder":_vm.lang.surveyIntegrations.channels.email.settings.email.subject[
          _vm.languageSelected
        ],"name":"subject"},on:{"keyup":_vm.onSubjectChange},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}}),_vm._v(" "),(_vm.errors.first('subject'))?_c('span',{staticClass:"error-msg"},[_vm._v("\n      "+_vm._s(_vm.errors.first('subject'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.bot)?_c('HtmlBuilder',{attrs:{"bot":_vm.bot,"html":_vm.html,"href":_vm.href},on:{"update:html":function($event){_vm.html=$event}}}):_vm._e(),_vm._v(" "),(_vm.errors.first('html'))?_c('span',{staticClass:"error-msg"},[_vm._v("\n      "+_vm._s(_vm.errors.first('html'))+"\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }